body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F6F6 !important;
  
  .css-1dga4vs {
        line-height: 30px !important;
  }

}

.stepper-container div div div div a {
  line-height: 30px !important;
}

.stepper-container div div div div span {
  line-height: 30px !important;
}



ul {
  list-style-type: none !important;
}



/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(219, 216, 216); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc9d5; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8e2ee; 
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0.05;
  width: 12px;
}


.p-hover:hover{
  color:#d3cfd6;
  cursor: pointer;
}

.vertical-timeline {
  padding: 10px 0px !important ;
}
.vertical-timeline-properties {
  top: 0px !important;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 60px !important;
  left: 12px;
  width: 4px;
  max-height: 68% !important;
}
.vertical-timeline-element {
  position: relative !important;
  margin: 16px 0px !important;
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: 0.25em;
  padding: 0px !important;
  box-shadow: none !important;
}
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #11B943, inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%);
}

/* CustomDatePickerRange styles */

.rmdp-calendar {
  padding: 10px !important;
}

.rmdp-prime.rmdp-wrapper {
  border: none!;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px 0px, rgba(0, 0, 0, 0.06) 0px 8px 16px 0px;
}

.rmdp-header {
  font-weight: 600 !important;
}

/* .rmdp-arrow {
  border: solid #693FF3 !important;
  border-width: 0 2px 2px 0;
} */

.rmdp-week-day {
  color: #693FF3 !important;
  font-weight: 600 !important;
}

.rmdp-range-hover {
  background-color: #693FF3 !important;
  opacity: 0.7 !important;
}

.rmdp-day {
  font-weight: 600 !important;
}
.rmdp-day.rmdp-today span {
  color: #693FF3 !important;
  font-weight: 600;
 }
 .rmdp-range {
  background-color: #693FF3 !important;
  color: "white" !important;
}

.pulse {
	transform: scale(1);
	animation: pulse 2s infinite;
  
}

@keyframes pulse {
	0% {
		transform: scale(0.90);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.90);
	}
}

.pulse {
	transform: scale(1);
	animation: pulse 4s infinite ;
}

@keyframes pulse {
	0% {
		transform: scale(0.98);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.98);
	}
}